
.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  padding: 10% auto;
  overflow-x: hidden;

}

/* Webkit-based browsers (Chrome, Safari) */
.App::-webkit-scrollbar {
  width: 8px; /* Adjust the width to make the scrollbar thin */
}

.App::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional: Background color for the track */
}

.App::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: Round the corners of the scrollbar */
}

.App::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the scrollbar thumb */
}

/* Firefox */
@-moz-document url-prefix() {
  .App {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1; /* thumb color and track color */
  }
}

/* Edge, IE, and other browsers */
.App {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1; /* thumb color and track color */
}

.header{
  /* height:30vh; */
  /* width:60%; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; */


}
.nav {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width:100%;
}
.nav button{
  /* width:20%; */
  border:none;
  background-color: transparent;
  /* text-decoration: underline; */
  font-size: medium;
}
.nav button:hover{
  color:#2d3d23;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  width:100%;
}

.progress-box {
  /* flex: 1; */
  text-align: center;
  /* padding: 10px; */
  background-color: #e5e7eb;
  border-right: 1px solid #ddd;
  width:100%;
  border-radius: 5px;
  /* font-size: large; */
  display: flex;
  align-items: center;justify-content: center;

}

.progress-box:last-child {
  border-right: none;
}

.progress-box.active {
  background-color: #2d3d23;
  color: white;
}
.progress-bar-container{
  display: flex;
  flex-direction: column;
  /* width:85%; */
}
.progressPercentage{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: small;

}
.progress-bar {
  height: 8px;
  background-color: #ddd;
  /* margin-top: 10px; */
  width:100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.progress {
  height: 8px;
  background-color: #2d3d23;
  transition: width 0.3s;
  border-radius: 20px;

}
p{
  margin: 0px;
}
.previous-btn-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.previous-btn-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.exit-btn-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.exit-btn-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.button-container{
  
}

@media (max-width: 600px) {
  .header{
    /* height:30vh; */
    /* width:90vw; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .progress-bar-container{
    /* width:70%; */

  }
  .progress-box {
    /* padding: 5px; */
    /* font-size: 0.8em; */
  }
  .previous-btn-icon{
    display: block;
  }
  .previous-btn-text{
  display: none;
  }
  .exit-btn-icon{
    display: block;
  }
  .exit-btn-text{
  display: none;
  }
}