.outside-container {
  height: 70vh;
  width: 50vw;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Remove default list styling */
ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  /* gap: 3; */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  
}

/* Styling for each list item (checkbox option) */
li {
  width: 100%;
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
  /* margin: 10px; */
  padding: 20px 0px 20px 0px;
  /* border: 1px solid #000; */
  /* border: none; */
  /* border-bottom: 2px solid #ccc; */
  margin-bottom: 15px;
  outline: none;
  text-align: center;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  font-weight: 400;
}

ul li:nth-last-child(1) {
  flex: 1 1 100%;
}

li img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 10px;
}

li p {
  margin: 0;
}

label,
input {
  cursor: pointer;
  font-size: 21px;
}


/* Styling for checkboxes */

/* input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
} */

/* Label styles */
label {
  /* margin-left: 5px; */
  /* Adjust spacing between checkbox and label text */
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 20px;
  width: 100%;
}
/* label:hover{
    background: green;
} */

.checkbox-text {
  text-align: left;
}

.question p {
  font-size: 21px;
  font-weight: 500;
  color: rgb(72, 72, 72);
  text-align: left;
}


.question .subquestion {
  font-size: 16px;
  font-weight: 400;
  color: rgb(110, 110, 110);
  text-align: left;
}

.head-question-title {
  font-size: 30px;
  font-weight: 600;
  color: rgb(110, 110, 110);
  text-align: left;
  margin-bottom: 20px;
}

.heading-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.heading {
  /* font-size: 21px; */
  font-weight: 500;
  color: rgb(72, 72, 72);
}

.input {
  /* border: none;
  border-bottom: 2px solid #ccc; */
  padding: 10px;
  margin-bottom: 15px;
  outline: none;
  width: 100%;
  height: 100%;
  /* background: red; */
}


.radio-container {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  /* align-items: flex-start; */
  gap: 10px;
  margin-bottom: 40px;
}

.button-container button {
  width: 300px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  background-color: #2d3d23;
  color: white;
 
  margin-bottom: 40px;
  cursor: pointer;
}

button::after {
  border: none;
}

button:hover {
  background-color: #2d3d23;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 30px;
  
}

@media (max-width: 768px) {
  ul {
    flex-direction: column;
    align-items: center;
  }

  li {
    flex: 1 1 100%;
  }
}

@media (max-width: 600px) {
  .outside-container {
    height: 70vh;
    width: 90vw;
    margin: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
  }

  .button-container button {
    /* width: 100%; */
    margin-bottom: 20px;
    font-size: 14px;
  }

  li {
    margin: 0px;
  }

 
  .question p{
    font-size: 14px;
  }
  label,
input {
  font-size: 13px;
}
}
